<template>
  <v-card>
    <v-card-title>
      <v-text-field
        style="max-width: 400px;"
        v-model="search"
        append-icon="search"
        :label="$tc('global.buscar')"
        clearable
        outlined
        dense
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-row>
      <v-col cols="12">
        <v-treeview
          v-if="!loading"
          :items="permissoes"
          :search="search"
          :open.sync="open"
          item-text="descricao"
          item-key="id"
          item-children="children"
          hoverable
          transition
          dense
        >
          <template v-slot:prepend="{ item, open }">
            <v-icon>
              {{ open ? "folder_open" : "folder" }}
            </v-icon>
          </template>
          <template v-slot:label="{ item }">
            <div class="text-body-2">{{ item.descricao }}</div>
          </template>
          <template v-slot:append="{ item }">
            <div class="d-flex justify-end align-center">
              <div class="d-none d-md-block text-caption">
                {{ item.componente }}
              </div>

              <v-btn
                v-if="vuePermissaoEdit"
                class="ml-8"
                @click="goToPermissao(item)"
                small
                depressed
                icon
              >
                <v-icon color="buttons">
                  keyboard_arrow_right
                </v-icon>
              </v-btn>
            </div>
          </template>
        </v-treeview>
        <Carregando v-else />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { getPermissoes } from "@/services/api/permissoes.api.js";
import { mapGetters } from "vuex";

export default {
  name: "ListaPermissoes",
  data: () => ({
    search: "",
    loading: false,
    permissoes: [],
    open: [],
  }),
  computed: {
    ...mapGetters("Componentes", ["getAccess"]),
    vuePermissaoEdit() {
      return this.getAccess("vuePermissao", "editar");
    },
  },
  methods: {
    goToPermissao(item) {
      this.$store.commit("UPDATE_DYNAMICCRUMB", item.descricao);
      this.$router.push({
        name: "Permissao",
        params: { permissaoId: item.id },
      });
    },
    getPermissaoPai(permissao_id) {
      let permissao_pai = "";
      this.permissoes.forEach((permissao) => {
        if (permissao.id == permissao_id) {
          permissao_pai = ". " + permissao.descricao;
        }
      });
      return permissao_pai;
    },
    getPermissoes() {
      this.loading = true;
      setTimeout(() => {
        getPermissoes()
          .then((response) => {
            this.permissoes = response;
            this.permissoes.forEach((permissao) => {
              this.open.push(permissao.id);
              permissao.children.forEach((child) => {
                this.open.push(child.id);
              });
            });
            this.loading = false;
          })
          .catch((error) => console.log(error))
          .finally(() => {
            this.loading = false;
          });
      }, 400);
    },
  },
  mounted() {
    this.getPermissoes();
  },
};
</script>

<style scoped lang="scss"></style>
