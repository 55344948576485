var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"search","label":_vm.$tc('global.buscar'),"clearable":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[(!_vm.loading)?_c('v-treeview',{attrs:{"items":_vm.permissoes,"search":_vm.search,"open":_vm.open,"item-text":"descricao","item-key":"id","item-children":"children","hoverable":"","transition":"","dense":""},on:{"update:open":function($event){_vm.open=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
var open = ref.open;
return [_c('v-icon',[_vm._v(" "+_vm._s(open ? "folder_open" : "folder")+" ")])]}},{key:"label",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-body-2"},[_vm._v(_vm._s(item.descricao))])]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-end align-center"},[_c('div',{staticClass:"d-none d-md-block text-caption"},[_vm._v(" "+_vm._s(item.componente)+" ")]),(_vm.vuePermissaoEdit)?_c('v-btn',{staticClass:"ml-8",attrs:{"small":"","depressed":"","icon":""},on:{"click":function($event){return _vm.goToPermissao(item)}}},[_c('v-icon',{attrs:{"color":"buttons"}},[_vm._v(" keyboard_arrow_right ")])],1):_vm._e()],1)]}}],null,false,1647423262)}):_c('Carregando')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }